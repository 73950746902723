<template>
  <v-container fluid v-if="!this.loading">
    <v-row>
      <v-col># {{ ticket.id }} Тема: {{ ticket.title }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list class="chat">
          <v-list-item
            :key="message.id"
            v-for="message in ticket.ticketMessages"
          >
            <v-list-item-content>
              <div
                class="date font-weight-bold"
                :class="{
                  'red--text': message.isAnswer,
                  'text-right': message.isAnswer
                }"
              >
                {{ message.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
              </div>
              <div
                class="date font-weight-bold"
                :class="{
                  'red--text': message.isAnswer,
                  'text-right': message.isAnswer
                }"
              >
                {{ message.isAnswer ? "Администратор:" : "Пользователь:" }}
                <router-link tag="a" :to="`/users/${message.cards.id}`">
                  <v-btn
                    :class="{
                      'red--text': message.isAnswer,
                      'blue--text': !message.isAnswer
                    }"
                    >{{ message.cards.nick }}</v-btn
                  >
                </router-link>
              </div>
              <div
                style="max-width: 100%; overflow-wrap: anywhere;"
                :class="{ 'text-right': message.isAnswer }"
              >
                {{ message.message }}
              </div>
              <div
                class="d-flex"
                v-if="message.images && message.images.length > 0"
              >
                <div
                  :key="idx"
                  v-for="(image, idx) in message.images"
                  class="preview-image-container ml-2"
                  @click.stop="showModal(image)"
                >
                  <div
                    class="preview-image"
                    :style="'background-image: url(' + image + ')'"
                  ></div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-textarea label="Сообщение" auto-grow v-model="message"></v-textarea>
        <v-btn
          v-show="[0, 1, 2].indexOf(this.ticket.state) >= 0"
          @click="closeTicket"
          color="red"
          >Закрыть</v-btn
        >
        <v-btn @click="sendMessage" class="ml-2" color="primary"
          >Отправить</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="900">
      <v-card>
        <v-card-title class="headline"> </v-card-title>
        <v-card-text>
          <div
            :style="
              `
          background-image: url(${this.dialogImage});
          display:block;
          width: 100%;
          height: 800px;
          background-size: contain;
          background-position: center;
          `
            "
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SupportService from "@/services/support.service";
import { mapGetters } from "vuex";
export default {
  name: "TicketView",
  data: () => ({
    ticket: {},
    message: "",
    dialog: false,
    dialogImage: null,
    loading: true
  }),
  computed: {
    ...mapGetters("user", ["user"])
  },
  methods: {
    showModal(image) {
      this.dialog = true;
      this.dialogImage = image;
    },
    closeTicket() {
      SupportService.update({
        data: {
          state: 3
        },
        where: {
          id: this.ticket.id
        }
      });
    },
    sendMessage() {
      SupportService.update({
        data: {
          state: 2,
          ticketMessages: {
            create: {
              message: this.message,
              isAnswer: true,
              card_id: this.user.id
            }
          }
        },
        where: {
          id: this.ticket.id
        }
      }).then(() => {
        this.message = "";
        this.loadTicket();
      });
    },
    loadTicket() {
      this.loading = true;
      SupportService.findUnique({
        where: {
          id: Number(this.$route.params.id)
        },
        include: {
          ticketMessages: {
            include: {
              cards: true
            }
          }
        }
      }).then(data => {
        console.log(data);
        this.ticket = data;
        try {
          this.ticket.images = JSON.parse(data.images);
        } catch (e) {
          console.log(e);
        }
        for (const message of this.ticket.ticketMessages) {
          try {
            message.images = JSON.parse(message.images);
          } catch (e) {
            console.log(e);
          }
        }
        this.loading = false;
      });
    }
  },
  created() {
    this.loadTicket();
  }
};
</script>

<style scoped lang="scss">
.date {
  margin-bottom: 5px;
  font-size: 18px;
  color: #1565c0;
}
.chat {
  height: calc(100vh - 400px);
  overflow-y: scroll;
}

.preview-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.preview-image-container {
  cursor: pointer;
  width: 300px;
  height: 200px;
  background: #e5e5e5;
}
</style>
