var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.loading)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._v("# "+_vm._s(_vm.ticket.id)+" Тема: "+_vm._s(_vm.ticket.title))])],1),_c('v-row',[_c('v-col',[_c('v-list',{staticClass:"chat"},_vm._l((_vm.ticket.ticketMessages),function(message){return _c('v-list-item',{key:message.id},[_c('v-list-item-content',[_c('div',{staticClass:"date font-weight-bold",class:{
                'red--text': message.isAnswer,
                'text-right': message.isAnswer
              }},[_vm._v(" "+_vm._s(_vm._f("moment")(message.createdAt,"YYYY-MM-DD HH:mm:ss"))+" ")]),_c('div',{staticClass:"date font-weight-bold",class:{
                'red--text': message.isAnswer,
                'text-right': message.isAnswer
              }},[_vm._v(" "+_vm._s(message.isAnswer ? "Администратор:" : "Пользователь:")+" "),_c('router-link',{attrs:{"tag":"a","to":`/users/${message.cards.id}`}},[_c('v-btn',{class:{
                    'red--text': message.isAnswer,
                    'blue--text': !message.isAnswer
                  }},[_vm._v(_vm._s(message.cards.nick))])],1)],1),_c('div',{class:{ 'text-right': message.isAnswer },staticStyle:{"max-width":"100%","overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(message.message)+" ")]),(message.images && message.images.length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((message.images),function(image,idx){return _c('div',{key:idx,staticClass:"preview-image-container ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.showModal(image)}}},[_c('div',{staticClass:"preview-image",style:('background-image: url(' + image + ')')})])}),0):_vm._e()])],1)}),1),_c('v-textarea',{attrs:{"label":"Сообщение","auto-grow":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:([0, 1, 2].indexOf(this.ticket.state) >= 0),expression:"[0, 1, 2].indexOf(this.ticket.state) >= 0"}],attrs:{"color":"red"},on:{"click":_vm.closeTicket}},[_vm._v("Закрыть")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.sendMessage}},[_vm._v("Отправить")])],1)],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"}),_c('v-card-text',[_c('div',{style:(`
        background-image: url(${this.dialogImage});
        display:block;
        width: 100%;
        height: 800px;
        background-size: contain;
        background-position: center;
        `)})])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }