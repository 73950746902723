import { api } from "@/instances/http";

class SupportService {
  findMany(params) {
    return api.post("/support/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/support/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params) {
    return api.post("/support/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/support/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/support/create", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new SupportService();
